<template>
  <div>
    <transition name="fade">
      <div v-if="content">
        <div class="mt-3 px-4 md:px-8 pb-4 border-b border-black">
          <ul v-if="content">
            <li v-for="project in projects" :key="project.id">
              <router-link :to="`/${project.slug}`" class="flex">
                <span
                  v-text="project.dates"
                  class="w-24 md:w-32 inline-block leading-snug"
                ></span>
                <span
                  v-html="project.display_title"
                  class="block flex-1 leading-snug"
                ></span>
              </router-link>
            </li>
            <li
              class="cursor-pointer hover:opacity-30"
              v-if="!showAllProjects"
              @click="showAllProjects = true"
            >
              <span class="w-24 md:w-32 inline-block leading-snug">&nbsp;</span>
              <span class="block flex-1 leading-snug">More...</span>
            </li>
          </ul>
        </div>
        <Project
          id="project"
          :key="currentProject.id"
          :project="currentProject"
          :mouse="mouse"
          ref="project"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import Project from "@/components/Project.vue"

export default {
  props: ["content", "mouse"],
  components: { Project },
  data() {
    return {
      showAllProjects: false,
    }
  },
  computed: {
    projects() {
      if (this.showAllProjects) {
        return this.$props.content
      } else {
        return this.$props.content.slice(0, 8)
      }
    },
    currentProject() {
      if (this.$route.params.slug) {
        return this.$props.content.find(
          (x) => x.slug === this.$route.params.slug
        )
      } else {
        return this.$props.content[0]
      }
    },
  },
  watch: {
    $route() {
      this.$scrollTo("#project", 250)
    },
  },
}
</script>

<style lang="css" scoped>
a.router-link-exact-active {
  color: red;
  pointer-events: none;
}
</style>
