<template>
  <div
    class="fixed inset-0 z-50 bg-white overflow-y-scroll py-20 px-24 overlay"
  >
    <div
      class="fixed top-0 right-0 mt-6 mr-10 cursor-pointer"
      @click="$emit('close')"
    >
      Close
    </div>
    <ul class="md:grid md:grid-cols-4 md:gap-36 w-full">
      <li
        v-for="(work, idx) in works"
        :key="work.title"
        class="flex flex-col justify-end hover-toggle group cursor-pointer"
        @click="$emit('goto', idx)"
      >
        <div v-if="work.image" v-html="work.image.srcset" />
        <div
          v-text="work.display_title"
          class="
            text-center text-sm
            transition-opacity
            duration-400
            mt-4
            opacity-0
            group-hover:opacity-100
            h-12
            leading-tight
          "
        />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Overview',
  props: ['works'],
}
</script>

<style lang="css" scoped>
>>> img {
  @apply w-full h-full object-contain;
}

.overlay {
  right: -16px;
}
</style>
