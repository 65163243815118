// import dayjs from 'dayjs'

export async function api (path) {

  let baseURL = process.env.VUE_APP_API_URL

  try {
    const res = await fetch(`${baseURL}${path}`, {
      mode: 'cors'
    })
    const json = await res.json()
    return json
  }
  catch (err) {
    return err
  }
}

// const formatDate = function (date, format = 'MMMM D') {
//   return dayjs(date).format(format)
// }

// const chapterDates = function (item) {
//   return `${formatDate(
//     item.start_date
//   )} &ndash; ${formatDate(item.end_date)}, 2021`
// }
