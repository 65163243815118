<template>
  <div id="app">
    <div
      class="
        flex flex-col
        md:flex-row
        items-start
        md:items-center
        justify-end
        md:justify-between
        mx-4
        md:mx-8
        mt-12
        md:mt-0
        mb-5
        md:mb-0
        relative
        z-3
      "
    >
      <div class="flex items-center relative z-40">
        <div class="fixed top-0 left-0 z-40">
          <div
            @click="scrollTop"
            class="
              cursor-pointer
              border border-black
              rounded-full
              h-4
              w-4
              bg-white
              mt-5
              md:mt-7
              ml-4
              md:ml-8
              relative
              hover:bg-black
              transition-all
              duration-300
            "
          ></div>
        </div>
        <div class="hidden md:block w-32"></div>
        <div class="break-none md:mt-6">
          <router-link to="/" class="text-black"
            >Michele&nbsp;Abramowitz</router-link
          >
        </div>
      </div>
      <div class="md:mt-6">
        <ul class="space-x-4">
          <li class="inline">
            <router-link to="/page/about">About</router-link>
          </li>
          <li class="inline" v-if="init && init.press">
            <router-link to="/page/press">Press</router-link>
          </li>
          <li class="inline">
            <router-link to="/page/contact">Contact</router-link>
          </li>
        </ul>
      </div>
    </div>
    <router-view :content="content" :mouse="mouse" />
  </div>
</template>

<script>
import { api } from './utils'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../tailwind.config.js'

export default {
  name: 'App',
  data() {
    return {
      init: null,
      content: null,
      mouse: null,
      tw: resolveConfig(tailwindConfig),
    }
  },
  mounted() {
    this.api('/init.json').then((x) => {
      this.init = x
    })

    this.api('/projects.json').then((x) => {
      this.content = x
      document.addEventListener('mousemove', this.mouseListener)
    })

    let re = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i
    window.isMobile = re.test(navigator.userAgent)
  },
  destroyed() {
    document.removeEventListener('mousemove', this.mouseListener)
  },
  methods: {
    api,
    mouseListener(e) {
      this.mouse = e.clientX / window.innerWidth
    },
    scrollTop() {
      let re = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i
      re.test(navigator.userAgent)
        ? this.$scrollTo('html')
        : this.$scrollTo('#app', 250)
    },
  },
}
</script>
