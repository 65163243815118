import Vue from 'vue'
import VueWaypoint from 'vue-waypoint'
import App from './App.vue'
import VueScrollTo from 'vue-scrollto'

import router from './router'
import './css/app.css'

Vue.config.productionTip = false

Vue.use(VueWaypoint)
Vue.use(VueScrollTo)

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
