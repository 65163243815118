<template>
  <div class="h-full w-full">
    <!-- SCRUBBER -->
    <!-- <div class="hidden md:flex mt-6 py-2 items-center relative">
      <div class="border-t border-black w-full" />
      <div
        class="
          border border-black
          rounded-full
          h-4
          w-4
          absolute
          left-0
          top-0
          bg-white
          z-10
        "
        :style="{ left: `${(currentTime / duration) * 100}%` }"
      />
    </div> -->

    <div class="h-full flex items-center -mt-8 md:mt-0">
      <transition name="fade">
        <!-- VIDEO -->
        <video
          v-if="src"
          :src="src"
          :data-src="src"
          ref="vid"
          class="mx-auto mt-8 md:mt-0"
          autoplay="true"
          key="video"
          loop
          muted
          playsinline
        />

        <!-- IMAGE -->
        <!-- <div
          v-else
          class="
            preview-image
            w-full
            h-full
            flex
            items-center
            justify-center
            mx-auto
            absolute
          "
          v-html="work.image.srcset"
          key="image"
        /> -->
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Video',
  props: ['current', 'mouse', 'work'],
  data() {
    return {
      currentIndex: 0,
      duration: null,
      currentTime: 0,
      autoplay: window.isMobile,
    }
  },
  computed: {
    src() {
      if (this.$props.current) {
        // return this.$props.work.video_mobile
        if (window.isMobile && this.$props.work.video_mobile) {
          return this.$props.work.video_mobile
        } else {
          return this.$props.work.video
        }
      } else {
        return false
      }
    },
  },
  mounted() {
    let vid = this.$refs.vid
    // window.setTimeout(() => {
    //   this.duration = this.$refs.vid.duration
    // }, 1500)

    if (vid) {
      vid.ondurationchange = () => {
        this.duration = vid.duration
      }
    }
  },
  // watch: {
  //   mouse() {
  //     if (this.$props.current) {
  //       this.currentTime = this.$props.mouse * this.duration
  //       this.$refs.vid.currentTime = this.currentTime
  //     }
  //   },
  // },
}
</script>

<style lang="css">
video {
  max-height: 100%;
}

.preview-image img {
  max-width: 80% !important;
  max-height: 80% !important;
}
</style>
