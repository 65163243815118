module.exports = {
  mode: 'jit',
  purge: ['./index.html', './src/**/*.{vue,js,ts,jsx,tsx}'],
  darkMode: false, // or 'media' or 'class'
  theme: {
    colors: {
      white: '#f6f6f7',
      black: 'rgb(0,0,0)',
      red: 'rgb(255,0,0)'
    },
    cursor: {
      auto: 'auto',
      default: 'default',
      pointer: 'pointer',
      prev: 'w-resize',
      next: 'e-resize'
    }
  },
  variants: {
    extend: {},
  },
  plugins: [],
}
