<template>
  <div class="mx-8 max-w-xl mt-6">
    <transition name="fade" mode="out-in">
      <div v-if="page" v-html="page"></div>
    </transition>
  </div>
</template>

<script>
import { api } from '../utils'

export default {
  name: 'Page',
  data() {
    return {
      page: null,
    }
  },
  methods: {
    fetch() {
      api(`${this.$route.params.slug}.json`).then((x) => {
        this.page = x.content
      })
    },
  },
  mounted() {
    this.fetch()
  },
  watch: {
    $route() {
      this.page = null

      this.fetch()
    },
  },
}
</script>

<style type="css" scoped>
>>> h1,
>>> h2 {
  @apply text-red;
}
>>> p + h1,
>>> p + h2 {
  @apply mt-12;
}

>>> ul + h2 {
  @apply mt-6;
}

>>> ul {
  @apply list-disc list-inside;
}

>>> img {
  @apply mt-12 max-w-sm pr-8 mb-12;
}

>>> a {
  @apply underline;
}

>>> ul {
  @apply w-full;
  
  
}

>>> li {
  @apply flex leading-snug;
}

>>> li div:first-child {
  @apply flex-grow-0 flex-shrink-0;
  width: 52px;
  font-variant-numeric: tabular-nums;
}
</style>
