<template>
  <div
    ref="project"
    class="py-4 px-4 md:py-6 md:px-8 flex flex-col relative"
    :style="panelStyle"
  >
    <div class="flex">
      <div class="w-full md:w-1/3 flex">
        <div class="w-32 inline-block">
          <span
            v-text="project.dates"
            class="transition-all duration-300"
            :class="{ 'pl-8 md:pl-10': scrolled }"
          ></span>
        </div>
        <div class="relative leading-tight mt-0.5">
          <div v-html="project.display_title"></div>
          <div
            v-if="currentWork"
            v-html="workTitle"
            class="hidden md:inline absolute whitespace-nowrap z-40"
          ></div>
        </div>
      </div>

      <!-- NAV CIRCLES -->
      <div class="w-1/3 mt-1 hidden md:flex justify-center space-x-2">
        <div
          class="
            item-dot
            border border-black
            rounded-full
            h-4
            w-4
            inline-block
            flex
            items-center
            justify-center
            cursor-pointer
          "
          v-for="(work, idx) in project.works"
          :key="work.id"
          @click="goto(idx)"
        >
          <span
            v-if="idx === currentIndex"
            class="bg-black h-2 w-2 rounded-full block"
          />
        </div>
      </div>

      <div class="w-1/3 text-right">
        <div class="space-x-6">
          <span
            @click="zoomUrl = currentWork.image.url"
            class="hidden md:inline cursor-pointer"
            >Zoom In</span
          >
          <span @click="showOverview = !showOverview" class="cursor-pointer"
            >Overview</span
          >
        </div>
        <div
          v-html="caption"
          class="
            absolute
            whitespace-nowrap
            -mt-1
            text-right
            right-0
            mr-8
            hidden
            md:block
            z-40
          "
        ></div>
      </div>
    </div>

    <!-- Mobile Title/Dots -->
    <div class="md:hidden">
      <div class="relative leading-tight mt-0.5">
        <div
          v-if="currentWork"
          v-html="workTitle"
          class="whitespace-nowrap transition-all duration-300"
          :class="{ 'pl-8 md:pl-10': scrolled }"
        ></div>
      </div>
      <div
        class="
          flex
          mt-6
          mb-2
          md:hidden
          justify-center
          md:justify-between
          space-x-1
        "
      >
        <div
          class="
            item-dot
            border border-black
            rounded-full
            h-4
            w-4
            inline-block
            flex
            items-center
            justify-center
            cursor-pointer
          "
          v-for="(work, idx) in project.works"
          :key="work.id"
          @click="goto(idx)"
        >
          <span
            v-if="idx === currentIndex"
            class="bg-black h-2 w-2 rounded-full block"
          />
        </div>
      </div>
    </div>

    <!-- SLIDES -->
    <div class="keen-slider h-full" ref="slider">
      <div
        v-for="(work, idx) in project.works"
        :key="work.id"
        class="h-full flex items-center keen-slider__slide cursor-zoom"
        @click="handleClick"
      >
        <!-- VIDEO -->
        <Video
          v-if="work.video"
          :work="work"
          :mouse="mouse"
          :current="idx === currentIndex"
          ref="vid"
        />

        <!-- IMAGE -->
        <div
          class="preview-image w-full h-full flex items-center justify-center"
          v-else-if="work.image"
          v-html="work.image.srcset"
        />
      </div>
    </div>

    <div
      v-if="currentWork"
      class="
        w-full
        absolute
        md:hidden
        bottom-0
        left-0
        w-full
        text-center
        p-8
        leading-tight
        text-xs
        z-40
      "
      v-html="caption"
    ></div>

    <div
      @click="slider.prev()"
      class="
        hidden
        md:block
        slider-nav
        cursor-prev
        w-1/3
        absolute
        bottom-0
        left-0
        z-40
      "
    ></div>
    <div
      @click="slider.next()"
      class="
        hidden
        md:block
        slider-nav
        cursor-next
        w-1/3
        absolute
        bottom-0
        right-0
        z-40
      "
    ></div>

    <transition name="fade">
      <PanZoom
        v-if="zoomUrl"
        :key="zoomUrl"
        :work="currentWork"
        :url="zoomUrl"
        @close="zoomUrl = null"
      />
    </transition>

    <transition name="fade">
      <Overview
        v-if="showOverview"
        @close="showOverview = false"
        @goto="goto"
        :works="project.works"
      />
    </transition>
  </div>
</template>

<script>
import "keen-slider/keen-slider.min.css"
import KeenSlider from "keen-slider"
import Video from "./Video.vue"
import PanZoom from "./PanZoom.vue"
import Overview from "./Overview.vue"
import "lazysizes"

export default {
  name: "Project",
  props: ["project", "mouse"],
  data() {
    return {
      zoom: false,
      currentIndex: 0,
      duration: null,
      currentTime: 0,
      slider: null,
      top: false,
      scrolled: false,
      showOverview: false,
      zoomUrl: null,
      height: window.innerHeight,
      ready: false,
    }
  },
  computed: {
    canZoom() {
      return this.currentWork.image
    },
    currentWork() {
      return this.$props.project.works[this.currentIndex]
    },
    workTitle() {
      return `<em>${this.currentWork.title}</em> (${this.currentWork.year})`
    },
    caption() {
      if (this.currentWork.media && this.currentWork.dimensions) {
        return `${this.currentWork.media}, ${this.currentWork.dimensions}`
      } else {
        return this.currentWork.media || this.currentWork.dimensions
      }
    },
    panelStyle() {
      return {
        height: this.height + "px",
      }
    },
  },
  components: {
    Overview,
    Video,
    PanZoom,
  },
  mounted() {
    this.slider = new KeenSlider(this.$refs.slider, {
      loop: true,
      initial: this.$route.hash ? this.$route.hash.slice(1) : 0,
      beforeChange: (slider) => {
        this.currentIndex = slider.details().relativeSlide
      },
      afterChange: (slider) => {
        this.currentIndex = slider.details().relativeSlide
        if (this.ready) {
          this.$router.replace({ hash: `#${this.currentIndex}` })
        }
      },
      mounted: (slider) => {
        document.body.addEventListener("keydown", (event) => {
          const key = event.key
          switch (key) {
            case "ArrowLeft":
              slider.prev()
              break
            case "ArrowRight":
              slider.next()
              break
          }
        })
        window.addEventListener("scroll", this.onScroll)

        if (this.$route.hash) {
          this.$scrollTo("#project", 250)
          this.ready = true
        }
      },
    })
  },
  methods: {
    goto(idx) {
      this.showOverview = false
      this.slider.moveToSlide(idx)
    },
    onScroll() {
      this.height = window.innerHeight

      if (window.scrollY > 150) {
        this.scrolled = true
      } else {
        this.scrolled = false
      }
    },
    handleClick() {
      // this.zoomUrl = e.target.getAttribute('data-original')
      this.zoomUrl = this.currentWork.image.url
    },
  },
  watch: {
    currentIndex() {
      this.$router.replace({ hash: `#${this.currentIndex}` })
    }
  }
}
</script>

<style lang="css">
.preview-image img {
  @apply w-full h-full object-contain;
}

.item-dot {
  display: block;
  &:hover {
    transform: scale(2.1);
  }
}

.slider-nav {
  height: calc(100vh - 72px);
}
</style>
