import Vue from 'vue'
import Router from 'vue-router'
import Home from "./views/Home.vue"
import Page from "./views/Page.vue"

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/page/:slug",
      name: "Page",
      component: Page
    },
    {
      path: "/:slug?",
      name: "Home",
      component: Home
    },
  ]
})
