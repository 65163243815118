<template>
  <div class="fixed inset-0 bg-white z-50 flex items-center justify-center">
    <div class="fixed top-0 left-0 right-0 p-4 md:px-4 px-6 z-50 flex items-center justify-between">
      <span class="cursor-pointer" @click="$emit('close')">Close</span>
      <span class="cursor-pointer" @click="reset">Reset</span>
      </div>
    <img ref="image" :src="url" />
  </div>
</template>

<script>
import panzoom from 'panzoom'

export default {
  name: 'PanZoom',
  props: ['work', 'url'],
  data() {
    return {
      zoom: null
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.zoom = panzoom(this.$refs.image, {
        minZoom: 0.5,
        maxZoom: 5,
        // transformOrigin: { x: 0.5, y: 0.5 },
        onTouch: function (e) {
          if (e.target.tagName !== 'IMG') {
            return false // tells the library to not preventDefault.
          } else {
            return true
          }
        },
      })

      document.addEventListener('keydown', this.handleKey)
    })
  },
  destroyed() {
    document.removeEventListener('keydown', this.handleKey)
  },
  methods: {
    handleKey(e) {
      if (e.key === 'Escape') {
        const isNotCombinedKey = !(e.ctrlKey || e.altKey || e.shiftKey);
        if (isNotCombinedKey) {
          this.$emit('close')
        }
      }
    },
    reset() {
      this.zoom.zoomAbs(0,0,1)
      this.zoom.moveTo(0,0)
    }
  }
}
</script>
